import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const aiKey = document.querySelector("body").dataset.aiKey;
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: aiKey,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        disableExceptionTracking: false
    },
});

appInsights.loadAppInsights();

window.addEventListener("unhandledrejection", function (e) {
    appInsights.trackException({
        exception: new Error(e.reason.toString())
    });
});